<template>
  <div id="vload">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.vload.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/vload.png" alt />
              <p>{{ $t('deposit.method.desc', { method: $t('deposit.default.channels.vload') }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>
                  {{ $t('deposit.vload.inst1') }}
                </li>
              </ul>
              <ul v-html="$t('deposit.vload.inst2')"></ul>
            </div>
            <div class="form_main">
              <p class="title">
                {{ $t('deposit.method.form.header', { method: $t('deposit.default.channels.vload') }) }}
              </p>
              <el-form label-position="top" :model="form" ref="form" :rules="rules" status-icon>
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="validCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('deposit.vload.form.voucherpin')" prop="voucherPin">
                        <el-input v-model="form.voucherPin" maxlength="100" data-testid="voucherPin"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="form.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import { apiVload_payment } from '@/resource';
import mixin from '@/mixins/page/deposit';

export default {
  name: 'Vload',
  components: { AccountNumber },
  mixins: [mixin],
  data() {
    return {
      form: {
        accountNumber: '',
        voucherPin: '',
        notes: ''
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        voucherPin: [
          {
            required: true,
            message: this.$t('deposit.vload.form.voucherpinReq'),
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'EUR', 'JPY']
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber;
    },
    submitForm(e) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                window.location = result.data.msg;
              } else {
                this.loading = false;
                if (result.data.code === 500 && result.data.data) {
                  switch (result.data.data) {
                    case 503:
                      this.errorMessage(this.$t('deposit.default.failed'));
                      break;
                    case 4076:
                      this.errorMessage(this.$t('deposit.default.failed'));
                      break;
                    default:
                      this.errorMessage(this.$t('responseMsg.vload.' + result.data.data));
                      break;
                  }
                } else {
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiVload_payment(
        {
          mt4Account: this.form.accountNumber,
          voucherPIN: this.form.voucherPin,
          applicationNotes: this.form.notes
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
